import React, { useState, useContext } from 'react'
import { defaulFrontAxletDistance, defaultFrontMaxPreasureLoad, defaultFrontNoPressureLoad, defaulRearAxleDistance, defaultRearNoPressureLoad, defaultRearMaxPreasureLoad } from '../constants/init-defaults'
import { PaletteContext } from './paletteContext'
import { calculatePressure } from './axle-pressure/axle-preasure'

export interface AxlePressure {
    frontLoadAxlePressure: number
    rearLoadAxlePressure: number
}

export interface TruckAxleLoad {
    frontAxleDistance: number
    frontNoPressureLoad: number
    frontMaxPressureLoad: number

    rearAxleDistance: number
    rearNoPressureLoad: number
    rearMaxPressureLoad: number
}

export interface AxleLoadContext extends TruckAxleLoad, AxlePressure {
    setAxleLoad: (truckAxleLoad: TruckAxleLoad) => void
}

export const AxleLoadContext = React.createContext<AxleLoadContext>({} as AxleLoadContext)

export const AxleLoadContextComponent = (props) => {
    const paletteContext = useContext(PaletteContext)

    const [frontAxleDistance, setFrontAxleDistance] = useState(defaulFrontAxletDistance)
    const [frontNoPressureLoad, setFrontNoPressureLoad] = useState(defaultFrontNoPressureLoad)
    const [frontMaxPressureLoad, setFrontMaxPressureLoad] = useState(defaultFrontMaxPreasureLoad)

    const [rearAxleDistance, setRearAxleDistance] = useState(defaulRearAxleDistance)
    const [rearNoPressureLoad, setRearNoPressureLoad] = useState(defaultRearNoPressureLoad)
    const [rearMaxPressureLoad, setRearMaxPressureLoad] = useState(defaultRearMaxPreasureLoad)

    function setAxleLoad(truckAxleLoad: TruckAxleLoad) {
        setFrontAxleDistance(truckAxleLoad.frontAxleDistance)
        setFrontNoPressureLoad(truckAxleLoad.frontNoPressureLoad)
        setFrontMaxPressureLoad(truckAxleLoad.frontMaxPressureLoad)
        setRearAxleDistance(truckAxleLoad.rearAxleDistance)
        setRearNoPressureLoad(truckAxleLoad.rearNoPressureLoad)
        setRearMaxPressureLoad(truckAxleLoad.rearMaxPressureLoad)
    }
    const axlePressure = calculatePressure(paletteContext.palettes, frontAxleDistance, rearAxleDistance)

    return (
        <AxleLoadContext.Provider value={{
            frontAxleDistance,
            frontNoPressureLoad,
            frontMaxPressureLoad,

            frontLoadAxlePressure: axlePressure.frontLoadAxlePressure,

            rearAxleDistance,
            rearNoPressureLoad,
            rearMaxPressureLoad,

            rearLoadAxlePressure: axlePressure.rearLoadAxlePressure,

            setAxleLoad,
        }}>
            {props.children}
        </AxleLoadContext.Provider >
    )
}