import React from 'react'
import styled from 'styled-components'
import { redValidationError, black } from '../styles/colors'

const PercentageStyle = styled.span`
    color: ${props => props.percentage < 0 || props.percentage > 100 ? redValidationError : black};
`

export interface PercentageProps {
    percentage: number
}

export const Percentage = (props: PercentageProps) => {
    return (<PercentageStyle percentage={props.percentage}>{props.percentage}%</PercentageStyle>)
}