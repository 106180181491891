import React, { useState } from 'react'

export interface VerticalCompactContext {
    verticalCompact: boolean;
    setVerticalCompact: (newValue: boolean) => void;
    toggleVerticalCompact: () => void;
}

export const VerticalCompactContext = React.createContext<VerticalCompactContext>({} as VerticalCompactContext)

export const VerticalCompactContextComponent = (props) => {
    const [verticalCompact, setVerticalCompact] = useState(true)
    function toggleVerticalCompact() {
        setVerticalCompact(!verticalCompact)
    }

    return (
        <VerticalCompactContext.Provider value={{
            verticalCompact,
            setVerticalCompact,
            toggleVerticalCompact,
        }}>
            {props.children}
        </VerticalCompactContext.Provider>
    )
}