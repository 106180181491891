import React, { useContext } from 'react';
import { LoadingSpaceContext } from '../../../context/loadingSpaceContext';
import { ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function SelectedCapacity() {
    const { t } = useTranslation()

    const loadingSpaceContext = useContext(LoadingSpaceContext)
    return (<ListItemText>{t('selectedCapacity')}: {loadingSpaceContext.capacity} kg</ListItemText>)
}
