import React, { useState, useContext } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogContentText, DialogActions, Button, TextField, useTheme, makeStyles, createStyles } from '@material-ui/core';
import { AllChildrenFlex } from '../../shared/styles/containers';
import { getNumberInputError, getIntegerInputError } from '../../shared/common';
import { defaulFrontAxletDistance, defaulRearAxleDistance, defaultFrontNoPressureLoad, defaultFrontMaxPreasureLoad, defaultRearNoPressureLoad, defaultRearMaxPreasureLoad } from '../../constants/init-defaults';
import { AxleLoadContext } from '../../context/axle-load.context';
import { useTranslation } from 'react-i18next';

const frontAxleDistanceValidation = (frontAxleDistance: string, rearAxleDistance: string, errorMessage: string): string => {
    const frontError: string = getIntegerInputError(frontAxleDistance + '')
    const rearError: string = getIntegerInputError(rearAxleDistance + '')
    if (!frontError && !rearError) {
        if (frontAxleDistance >= rearAxleDistance) {
            return errorMessage
        }
    }
    return frontError
}

const rearAxleDistanceValidation = (frontAxleDistance: string, rearAxleDistance: string, errorMessage: string): string => {
    const frontError: string = getIntegerInputError(frontAxleDistance + '')
    const rearError: string = getIntegerInputError(rearAxleDistance + '')
    if (!frontError && !rearError) {
        if (frontAxleDistance >= rearAxleDistance) {
            return errorMessage
        }
    }
    return rearError
}

export const styles = makeStyles(() =>
    createStyles({
        textField: {
            minWidth: 250
        },
        img: {
            margin: '0 auto',
            display: 'block'
        }
    }),
);

export interface AxleLoadProps {
    open: boolean;
    handleClose: () => void;
}

export default function AxleLoadDialog(props: AxleLoadProps) {
    const { t } = useTranslation()
    const theme = useTheme()
    const classes = styles()

    const axleLoadContext = useContext(AxleLoadContext)

    const [frontAxleDistance, setFrontAxleDistance] = useState<any>(defaulFrontAxletDistance)
    const [frontNoPressureLoad, setFrontNoPressureLoad] = useState<any>(defaultFrontNoPressureLoad)
    const [frontMaxPressureLoad, setFrontMaxPressureLoad] = useState<any>(defaultFrontMaxPreasureLoad)

    const [rearAxleDistance, setRearAxleDistance] = useState<any>(defaulRearAxleDistance)
    const [rearNoPressureLoad, setRearNoPressureLoad] = useState<any>(defaultRearNoPressureLoad)
    const [rearMaxPressureLoad, setRearMaxPressureLoad] = useState<any>(defaultRearMaxPreasureLoad)

    const handleSubmit = (event) => {
        event.preventDefault()
        axleLoadContext.setAxleLoad({
            frontAxleDistance: +frontAxleDistance,
            frontMaxPressureLoad: +frontMaxPressureLoad,
            frontNoPressureLoad: +frontNoPressureLoad,
            rearAxleDistance: +rearAxleDistance,
            rearMaxPressureLoad: +rearMaxPressureLoad,
            rearNoPressureLoad: +rearNoPressureLoad,
        })
        props.handleClose()
    }

    const frontAxleDistanceError: string = frontAxleDistanceValidation(frontAxleDistance, rearAxleDistance, t('frontAxleMustPrecedeRearAxle'))
    const frontNoPressureLoadError: string = getNumberInputError(frontNoPressureLoad + '')
    const frontMaxPressureLoadError: string = getNumberInputError(frontMaxPressureLoad + '')

    const rearAxleDistanceError: string = rearAxleDistanceValidation(frontAxleDistance, rearAxleDistance, t('rearAxleMustBeBehindFrontAxle'))
    const rearNoPressureLoadError: string = getNumberInputError(rearNoPressureLoad + '')
    const rearMaxPressureLoadError: string = getNumberInputError(rearMaxPressureLoad + '')

    const isFormInvalid: boolean = !!frontAxleDistanceError || !!frontNoPressureLoadError || !!frontMaxPressureLoadError || !!rearAxleDistanceError || !!rearNoPressureLoadError || !!rearMaxPressureLoadError

    return (
        <Dialog onClose={props.handleClose} open={props.open} maxWidth="xl">
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <DialogTitle>{t('setAxleLoad')}</DialogTitle>
                <DialogContent>

                    <DialogContentText>
                        {t('frontAxle')}
                    </DialogContentText>

                    <AllChildrenFlex>
                        <TextField
                            label={`${t('distanceFromLoadingSpace')} (cm)`}
                            variant="outlined"
                            className={classes.textField}
                            error={!!frontAxleDistanceError}
                            helperText={frontAxleDistanceError}
                            value={frontAxleDistance}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFrontAxleDistance(event.target.value.replace(',', '.'))}
                        />
                        <TextField
                            label={`${t('pressureWithoutLoad')} (kg)`}
                            variant="outlined"
                            className={classes.textField}
                            error={!!frontNoPressureLoadError}
                            helperText={frontNoPressureLoadError}
                            value={frontNoPressureLoad}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFrontNoPressureLoad(event.target.value.replace(',', '.'))}
                        />
                        <TextField
                            label={`${t('maxAllowedPressure')} (kg)`}
                            variant="outlined"
                            className={classes.textField}
                            error={!!frontMaxPressureLoadError}
                            helperText={frontMaxPressureLoadError}
                            value={frontMaxPressureLoad}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFrontMaxPressureLoad(event.target.value.replace(',', '.'))}
                        />
                    </AllChildrenFlex>

                    <DialogContentText style={{ marginTop: theme.spacing(1) }}>
                        {t('rearAxle')}
                    </DialogContentText>

                    <AllChildrenFlex>
                        <TextField
                            label={`${t('distanceFromLoadingSpace')} (cm)`}
                            variant="outlined"
                            className={classes.textField}
                            error={!!rearAxleDistanceError}
                            helperText={rearAxleDistanceError}
                            value={rearAxleDistance}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRearAxleDistance(event.target.value.replace(',', '.'))}
                        />
                        <TextField
                            label={`${t('pressureWithoutLoad')} (kg)`}
                            variant="outlined"
                            className={classes.textField}
                            error={!!rearNoPressureLoadError}
                            helperText={rearNoPressureLoadError}
                            value={rearNoPressureLoad}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRearNoPressureLoad(event.target.value.replace(',', '.'))}
                        />
                        <TextField
                            label={`${t('maxAllowedPressure')} (kg)`}
                            variant="outlined"
                            className={classes.textField}
                            error={!!rearMaxPressureLoadError}
                            helperText={rearMaxPressureLoadError}
                            value={rearMaxPressureLoad}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setRearMaxPressureLoad(event.target.value.replace(',', '.'))}
                        />
                    </AllChildrenFlex>
                    <img className={classes.img} alt="truck" src={process.env.PUBLIC_URL + '/img/axle-load-truck.png'}/>

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleSubmit} color="primary" disabled={isFormInvalid} type="submit">
                        {t('set')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
