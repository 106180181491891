import React, { useState, useContext } from 'react'
import { Palette, getEmptyPalete } from '../types/palette'
import { v4 as uuidv4 } from 'uuid';
import { LoadingSpaceContext } from './loadingSpaceContext';

export interface UpdatePaletteParams {
    i: string
    x: number
    y: number
}

export interface PaletteContext {
    palettes: Palette[]
    increasePaletteCount: (id: string) => void
    addPalette: (w: number, h: number, weight: number) => void
    removePalette: (i: string) => void
    rotatePalette: (i: string) => void
    updatePalettePosition: (palete: UpdatePaletteParams) => void
    updatePalettesPosition: (palettes: UpdatePaletteParams[]) => void
    removeAll: () => void
}

export const PaletteContext = React.createContext<PaletteContext>({} as PaletteContext)

export const PaletteContextComponent = (props) => {
    const [palettes, setPalettes] = useState<Palette[]>([])
    const loadingSpaceContext = useContext(LoadingSpaceContext)

    function addPalette(w: number, h: number, weight: number) {
        const newYposition = loadingSpaceContext.length - h
        setPalettes([...palettes, getEmptyPalete(w, h, 0, newYposition, weight)])
    }

    function removePalette(i: string) {
        setPalettes([...palettes.filter((p: Palette) => p.i !== i)])
    }

    function updatePalettesPosition(inPalettes: UpdatePaletteParams[]) {
        setPalettes([...palettes.map((ctxPalette: Palette) => {
            const foundPalette = inPalettes.filter((searchedPalette: UpdatePaletteParams) => searchedPalette.i === ctxPalette.i)[0]
            return {
                ...ctxPalette,
                x: foundPalette.x,
                y: foundPalette.y,
            }
        })])
    }

    function updatePalettePosition(palette: UpdatePaletteParams) {
        setPalettes([...palettes.map((p: Palette) => {
            if (p.i !== palette.i) {
                return p
            } else {
                return {
                    ...p,
                    x: palette.x,
                    y: palette.y,
                }
            }
        })])
    }

    function increasePaletteCount(id: string) {
        const found: Palette | undefined = palettes.find(p => p.i === id);
        if (!!found) {
            const newYposition = loadingSpaceContext.length - found.h
            setPalettes([...palettes, getEmptyPalete(found.w, found.h, 0, newYposition, found.weight)])
        }
    }

    function rotatePalette(i: string) {
        setPalettes([...palettes.map((p: Palette) => {
            if (p.i !== i) {
                return p
            } else {
                return {
                    ...p,
                    i: uuidv4(),
                    h: p.w,
                    w: p.h,
                }
            }
        })])
    }

    function removeAll() {
        setPalettes([])
    }

    return (
        <PaletteContext.Provider value={{
            palettes,
            addPalette,
            removePalette,
            rotatePalette,
            updatePalettePosition,
            updatePalettesPosition,
            increasePaletteCount,
            removeAll
        }}>
            {props.children}
        </PaletteContext.Provider>
    )
}