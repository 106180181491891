import React, { useContext } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import { AxleLoadContext } from '../../../context/axle-load.context';
import { roundToTwoDecimalPlaces } from '../../../shared/common';
import { Percentage } from '../../../shared/components/percentage.component';
import { useTranslation } from 'react-i18next';

export interface AxleLoadProps {
    nestedClass: string
}

export default function AxleLoad(props: AxleLoadProps) {
    const { t } = useTranslation()
    const axleLoadContext = useContext(AxleLoadContext)

    const frontAxleBaseWithLoadPressure = axleLoadContext.frontNoPressureLoad + axleLoadContext.frontLoadAxlePressure
    const frontAxleLeftPressure = axleLoadContext.frontMaxPressureLoad - frontAxleBaseWithLoadPressure
    const frontAxleLeftPressurePercentage = roundToTwoDecimalPlaces(100 - (frontAxleBaseWithLoadPressure / axleLoadContext.frontMaxPressureLoad * 100))

    const rearAxleBaseWithLoadPressure = axleLoadContext.rearNoPressureLoad + axleLoadContext.rearLoadAxlePressure
    const rearAxleLeftPressure = axleLoadContext.rearMaxPressureLoad - rearAxleBaseWithLoadPressure
    const rearAxleLeftPressurePercentage = roundToTwoDecimalPlaces(100 - (rearAxleBaseWithLoadPressure / axleLoadContext.rearMaxPressureLoad * 100))

    return (
        <div>
            {t('frontAxle')}:
            <ListItemText className={props.nestedClass}>
                <div>
                {t('distanceFromLoadingSpace')}: {axleLoadContext.frontAxleDistance} cm
                </div>
                <div>
                {t('remainingPressure')}: {roundToTwoDecimalPlaces(frontAxleLeftPressure)}kg (<Percentage percentage={frontAxleLeftPressurePercentage}></Percentage>)
                </div>
            </ListItemText>
            {t('rearAxle')}:
            <ListItemText className={props.nestedClass}>
                <div>
                {t('distanceFromLoadingSpace')}: {axleLoadContext.rearAxleDistance} cm
                </div>
                <div>
                {t('remainingPressure')}: {roundToTwoDecimalPlaces(rearAxleLeftPressure)}kg (<Percentage percentage={rearAxleLeftPressurePercentage}></Percentage>)
                </div>
            </ListItemText>
        </div>
    )
}
