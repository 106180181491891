import React, { useContext } from 'react';
import { LoadingSpaceContext } from '../../../context/loadingSpaceContext';
import { PaletteContext } from '../../../context/paletteContext';
import { Palette } from '../../../types/palette';
import { roundToTwoDecimalPlaces } from '../../../shared/common';
import { Percentage } from '../../../shared/components/percentage.component';
import { ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function OccupiedSpace() {
    const { t } = useTranslation()
    const loadingSpaceContext = useContext(LoadingSpaceContext)
    const paletteContext = useContext(PaletteContext)

    const weightSummed: number = paletteContext.palettes.reduce((acc, palette: Palette) => acc + palette.weight, 0)

    const percentage = roundToTwoDecimalPlaces(100 - (weightSummed / loadingSpaceContext.capacity * 100))
    const leftSpace = loadingSpaceContext.capacity - weightSummed

    return (<ListItemText>{t('remainingPayload')}: {leftSpace} kg (<Percentage percentage={percentage}></Percentage>)</ListItemText>)
}
