import React, { useState, useContext } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, DialogContentText, DialogActions, Button, TextField, useTheme, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { AllChildrenFlex, selectFullWidthStyles } from '../../shared/styles/containers';
import { getNumberInputError } from '../../shared/common';
import { defaultPaletteLoadingSpace, LoadingSpace, palettesSpaces, defaultPaletteLoadingSpaceIndex } from '../../constants/init-defaults';
import { PaletteContext } from '../../context/paletteContext';
import { useTranslation } from 'react-i18next';

export interface AddPaletteDialogProps {
    open: boolean;
    handleClose: () => void;
}

export default function AddPaletteDialog(props: AddPaletteDialogProps) {
    const { t } = useTranslation()
    const theme = useTheme()
    const classes = selectFullWidthStyles()

    const paletteContext = useContext(PaletteContext)

    const [width, setWidth] = useState<any>(defaultPaletteLoadingSpace.width)
    const [length, setLength] = useState<any>(defaultPaletteLoadingSpace.length)
    const [weight, setWeight] = useState<any>(defaultPaletteLoadingSpace.capacity)
    const [selectedPalette, setSelectedPalette] = useState(defaultPaletteLoadingSpaceIndex)

    const handleWidthChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value: string = event.target.value.replace(',', '.')
        setWidth(value)
    }

    const handleLenghtChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value: string = event.target.value.replace(',', '.')
        setLength(value)
    }

    const handleWeightChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value: string = event.target.value.replace(',', '.')
        setWeight(value)
    }

    const handlePaletteDropdownChange = (event: any) => {
        const selectedValue: LoadingSpace = palettesSpaces[+event.target.value];
        setSelectedPalette(+event.target.value)
        setWidth(selectedValue.width)
        setLength(selectedValue.length)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        paletteContext.addPalette(+width, +length, +weight)
        props.handleClose()
    }

    const lengthError: string = getNumberInputError(length + '')
    const widthError: string = getNumberInputError(width + '')
    const weightError: string = getNumberInputError(weight + '')
    const isFormInvalid: boolean = !!widthError || !!lengthError || !!weightError

    return (
        <Dialog onClose={props.handleClose} open={props.open}>
            <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <DialogTitle>{t('chooseCargoSize')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('enterAnyDimensions')}
                    </DialogContentText>

                    <AllChildrenFlex>
                        <TextField
                            label={`${t('width')} (cm)`}
                            variant="outlined"
                            className='text-field'
                            error={!!widthError}
                            helperText={widthError}
                            value={width}
                            onChange={handleWidthChanged}
                        />
                        <TextField
                            label={`${t('length')} (cm)`}
                            variant="outlined"
                            className='text-field'
                            error={!!lengthError}
                            helperText={lengthError}
                            value={length}
                            onChange={handleLenghtChanged}
                        />
                        <TextField
                            label={`${t('weight')} (kg)`}
                            variant="outlined"
                            className='text-field'
                            error={!!weightError}
                            helperText={weightError}
                            value={weight}
                            onChange={handleWeightChanged}
                        />
                    </AllChildrenFlex>

                    <DialogContentText style={{ marginTop: theme.spacing(1) }}>
                        {t('orChooseFromPredefined')}
                    </DialogContentText>

                    <FormControl variant="outlined">
                        <InputLabel id="dimensions-label">{t('dimensions')}</InputLabel>
                        <Select
                            labelId="dimensions-label"
                            className={classes.select}
                            value={selectedPalette}
                            onChange={handlePaletteDropdownChange}
                            labelWidth={60}
                        >
                            {palettesSpaces.map((v, index) => (
                                <MenuItem key={index} value={index}>{v.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.handleClose} color="primary">
                        {t('cancel')}
                    </Button>
                    <Button onClick={handleSubmit} color="primary" disabled={isFormInvalid} type="submit">
                        {t('add')}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}
