import React, { useContext } from 'react';
import { LoadingSpaceContext } from '../../../context/loadingSpaceContext';
import { PaletteContext } from '../../../context/paletteContext';
import { Palette } from '../../../types/palette';
import { roundToTwoDecimalPlaces } from '../../../shared/common';
import { Percentage } from '../../../shared/components/percentage.component';
import { ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

export default function LeftSpace() {
    const { t } = useTranslation()
    const loadingSpaceContext = useContext(LoadingSpaceContext)
    const paletteContext = useContext(PaletteContext)

    let sumPatetteSurface: number = 0;
    paletteContext.palettes.forEach((palette: Palette) => {
        sumPatetteSurface += palette.w * palette.h
    });
    const busOverallCargoSpace = loadingSpaceContext.length * loadingSpaceContext.width;
    const leftSpace = roundToTwoDecimalPlaces((busOverallCargoSpace - sumPatetteSurface) / 10000)
    const percentage = sumPatetteSurface / busOverallCargoSpace * 100
    const percentageUsed = roundToTwoDecimalPlaces(100 - percentage)

    return <ListItemText>{t('remainingSpace')}: {leftSpace} m<sup>2</sup> (<Percentage percentage={percentageUsed}></Percentage>)</ListItemText>
}
