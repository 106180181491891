import React, { useState } from 'react'

export interface PreventCollisionContext {
    preventCollision: boolean
    setPreventCollision: (newValue: boolean) => void
    togglePreventCollision: () => void
}

export const PreventCollisionContext = React.createContext<PreventCollisionContext>({} as PreventCollisionContext)

export const PreventCollisionContextComponent = (props) => {
    const [preventCollision, setPreventCollision] = useState(true)
    function togglePreventCollision() {
        setPreventCollision(!preventCollision)
    }
    return (
        <PreventCollisionContext.Provider value={{
            preventCollision,
            setPreventCollision,
            togglePreventCollision,
        }}>
            {props.children}
        </PreventCollisionContext.Provider>
    )
}