import { v4 as uuidv4 } from 'uuid';

// drag and drop contract do not change
export interface Palette {
    w: number
    h: number
    x: number
    y: number
    i: string
    weight: number
}

export const getEmptyPalete = (w: number, h: number, x: number, y: number, weight: number): Palette => ({
    h: h,
    w: w,
    i: uuidv4(),
    x: x,
    y: y,
    weight: weight
})