import React from 'react'
import './App.css'
import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/react-resizable/css/styles.css'
import { Contexts } from './context/contexts'
import ClippedDrawer from './components/layout/main'

function App() {
  return (
  <Contexts>
    <ClippedDrawer></ClippedDrawer>
  </Contexts>
  )
}

export default App
