import { valueCantBeEmpty, valueMustBeAnumber, valueMustBePositive, valueMustBeInteger } from '../constants/error-messages';

export const getNumberInputError = (value: string): string => {
    if (!value) {
        return valueCantBeEmpty
    } else if (isNaN(+value)) {
        return valueMustBeAnumber
    } else if (value.indexOf(',') > 0 || value.indexOf('.') > 0) {
        return valueMustBeInteger
    } else if (+value <= 0) {
        return valueMustBePositive
    }
    return ''
}

export const getIntegerInputError = (value: string): string => {
    if (!value) {
        return valueCantBeEmpty
    } else if (isNaN(+value)) {
        return valueMustBeAnumber
    } else if (value.indexOf(',') > 0 || value.indexOf('.') > 0) {
        return valueMustBeInteger
    }
    return ''
}


export const roundToTwoDecimalPlaces = (number: number): number =>
    Math.round((number + Number.EPSILON) * 100) / 100