import React, { useState, useContext } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { ListItemSecondaryAction } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { drawerStyle, defaultDrawerStyle } from '../../../shared/styles/containers';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import { VerticalCompactContext } from '../../../context/verticalCompactContext';
import { PreventCollisionContext } from '../../../context/preventCollisionContext';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import LeftSpace from './left-space';
import SelectedLoadingSpace from './selected-loading-space';
import SelectedCapacity from './selected-capacity';
import OccupiedSpace from './occupied-space';
import AxleLoad from './axle-load';
import { useTranslation } from 'react-i18next';

const drawerWidth = 400

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...defaultDrawerStyle(theme),
        drawer: { ...drawerStyle, width: drawerWidth },
        drawerPaper: { width: drawerWidth },
    }),
)

export default function RightDrawer() {
    const { t } = useTranslation()
    const classes = useStyles()

    const verticalCompactContext = useContext(VerticalCompactContext)
    const preventCollisionContext = useContext(PreventCollisionContext)

    const [addedPalettesExpanded, setAddedPalettesExpanded] = useState(true)
    const [axleLoadExpanded, setAxleLoadExpanded] = useState(true)
    const [informationsExpanded, setInformationsExpanded] = useState(true)

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            anchor="right"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <div className={classes.toolbar} />

            <List>
                <ListItem button onClick={() => setInformationsExpanded(!informationsExpanded)}>
                    <ListItemText primary={t('informations')} />
                    {informationsExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={informationsExpanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            <div>
                                <LeftSpace></LeftSpace>
                                <OccupiedSpace></OccupiedSpace>
                                <SelectedLoadingSpace></SelectedLoadingSpace>
                                <SelectedCapacity></SelectedCapacity>
                            </div>
                        </ListItem>
                    </List>
                </Collapse>
            </List>

            <Divider />

            <List>
                <ListItem button onClick={() => setAxleLoadExpanded(!axleLoadExpanded)}>
                    <ListItemText primary={t('axlePressure')} />
                    {axleLoadExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={axleLoadExpanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            <AxleLoad nestedClass={classes.nested}></AxleLoad>
                        </ListItem>
                    </List>
                </Collapse>
            </List>

            <Divider />
            <List>
                <ListItem button onClick={() => setAddedPalettesExpanded(!addedPalettesExpanded)}>
                    <ListItemText primary={t('settings')} />
                    {addedPalettesExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={addedPalettesExpanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button className={classes.nested} onClick={() => verticalCompactContext.toggleVerticalCompact()}>
                            <ListItemIcon>
                                <VerticalAlignTopIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('autoCompact')} />
                            <ListItemSecondaryAction>
                                <Switch
                                    edge="end"
                                    color="primary"
                                    onChange={() => verticalCompactContext.toggleVerticalCompact()}
                                    checked={verticalCompactContext.verticalCompact}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                        <ListItem button className={classes.nested} onClick={() => preventCollisionContext.togglePreventCollision()}>
                            <ListItemIcon>
                                <ZoomOutMapIcon />
                            </ListItemIcon>
                            <ListItemText primary={t('preventCollision')} />
                            <ListItemSecondaryAction>
                                <Switch
                                    edge="end"
                                    color="primary"
                                    onChange={() => preventCollisionContext.togglePreventCollision()}
                                    checked={preventCollisionContext.preventCollision}
                                />
                            </ListItemSecondaryAction>
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </Drawer>
    )
}
