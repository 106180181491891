import React from 'react';
import LoadingSpaceContextComponent from './loadingSpaceContext';
import { PaletteContextComponent } from './paletteContext';
import { VerticalCompactContextComponent } from './verticalCompactContext';
import { PreventCollisionContextComponent } from './preventCollisionContext';
import { AxleLoadContextComponent } from './axle-load.context';

export const Contexts = (props) => (
    <PreventCollisionContextComponent>
        <LoadingSpaceContextComponent>
            <PaletteContextComponent>
                <AxleLoadContextComponent>
                    <VerticalCompactContextComponent>
                        {props.children}
                    </VerticalCompactContextComponent>
                </AxleLoadContextComponent>
            </PaletteContextComponent>
        </LoadingSpaceContextComponent>
    </PreventCollisionContextComponent>
)