import React, { useState, useContext } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import { defaultDrawerStyle } from '../../../shared/styles/containers';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import QueueIcon from '@material-ui/icons/Queue';
import LoadingSpaceDialog from '../../dialog/loading-space';
import AddPaletteDialog from '../../dialog/add-palette';
import LoadItem from './load-item';
import { PaletteContext } from '../../../context/paletteContext';
import { Palette } from '../../../types/palette';
import InfoIcon from '@material-ui/icons/Info';
import { Button } from '@material-ui/core';
import AxleLoadDialog from '../../dialog/axle-load';
import { useTranslation } from 'react-i18next';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import { loadingSpace, addCargo, setAxlePressure, addedCargo, noCargoAdded, removeAll } from '../../../locales/translation-keys';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...defaultDrawerStyle(theme),
        deleteAllButton: {
            margin: '0 auto',
            display: 'block'
        }
    }),
)

export default function LeftDrawer() {
    const classes = useStyles()
    const { t } = useTranslation()

    const paletteContext = useContext(PaletteContext)

    const [addedPalettesExpanded, setAddedPalettesExpanded] = useState(true)
    const [loadingSpaceDialogOpen, setLoadingSpaceDialogOpen] = useState(false)
    const [addPaletteDialogOpen, setAddPaletteDialogOpen] = useState(false)
    const [axleLoadDialogOpen, setAxleLoadDialogOpen] = useState(false)

    const firstSideMenuElements = [
        { text: t(loadingSpace), icon: LocalShippingIcon, click: () => setLoadingSpaceDialogOpen(true) },
        { text: t(addCargo), icon: QueueIcon, click: () => setAddPaletteDialogOpen(true) },
        { text: t(setAxlePressure), icon: ArrowDropDownCircleIcon, click: () => setAxleLoadDialogOpen(true) },
    ]

    const palettes = paletteContext.palettes.reduce<any>((palettes: any[], currPalette: Palette) => {
        const found: any = palettes.find((p: Palette) => p.w === currPalette.w && p.h === currPalette.h && currPalette.weight === p.weight)
        if (!!found) {
            found.count += 1
        } else {
            palettes.push({ ...currPalette, text: `${currPalette.w} x ${currPalette.h} (${currPalette.weight}kg)`, count: 1 })
        }
        return palettes
    }, [])

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <LoadingSpaceDialog open={loadingSpaceDialogOpen} handleClose={() => setLoadingSpaceDialogOpen(false)}></LoadingSpaceDialog>
            <AddPaletteDialog open={addPaletteDialogOpen} handleClose={() => setAddPaletteDialogOpen(false)}></AddPaletteDialog>
            <AxleLoadDialog open={axleLoadDialogOpen} handleClose={() => setAxleLoadDialogOpen(false)}></AxleLoadDialog>

            <div className={classes.toolbar} />
            <List>
                {firstSideMenuElements.map((element) => {
                    const Icon = element.icon;
                    return (
                        <ListItem button key={element.text} onClick={element.click}>
                            <ListItemIcon><Icon></Icon></ListItemIcon>
                            <ListItemText primary={element.text} />
                        </ListItem>
                    )
                })}
            </List>
            <Divider />
            <List>
                <ListItem button onClick={() => setAddedPalettesExpanded(!addedPalettesExpanded)}>
                    <ListItemText primary={t(addedCargo)} />
                    {addedPalettesExpanded ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={addedPalettesExpanded} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {palettes.map(palette => {
                            return (<LoadItem
                                id={palette.i}
                                key={palette.text}
                                text={palette.text}
                                count={palette.count}></LoadItem>)
                        })}
                        {palettes.length === 0 ? (
                            <ListItem>
                                <ListItemIcon>
                                    <InfoIcon />
                                </ListItemIcon>
                                <ListItemText primary={t(noCargoAdded)} />
                            </ListItem>
                        ) : (
                                <Button
                                    className={classes.deleteAllButton}
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => paletteContext.removeAll()}>
                                    {t(removeAll)}
                                </Button>
                            )}
                    </List>
                </Collapse>
            </List>
        </Drawer>
    )
}
