import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { BusContainer } from '../busContainer';
import { BasicLayout } from '../dragAndDropGrid';
import { toolbarStyle } from '../../shared/styles/containers';
import LeftDrawer from '../drawer/left/left-drawer';
import RightDrawer from '../drawer/right/right-drawer';
import { Button, IconButton } from '@material-ui/core';
import { white } from '../../shared/styles/colors';
import { useTranslation } from 'react-i18next';
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      margin: 'auto'
    },
    lngButton: {
      color: white
    },
    facebookButton: {
      color: white
    },
    toolBar: {
      justifyContent: 'space-between'
    },
    ...toolbarStyle(theme)
  }),
);

export default function ClippedDrawer() {
  const { t, i18n } = useTranslation()
  const classes = useStyles()

  function setLanguage(language: string) {
    localStorage.setItem('language', language)
    i18n.changeLanguage(language)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Typography variant="h6" noWrap>
            {t('appName')}
          </Typography>
          <div>
            <IconButton
              className={classes.facebookButton}
              aria-label="facebook page"
              component="span"
              onClick={() => {
                window.open("https://facebook.com/simpleloading")
              }}>
              <FacebookIcon />
            </IconButton>
            <Button
              className={classes.lngButton}
              variant="outlined"
              size="small"
              onClick={() => setLanguage('en')}>
              EN
            </Button>
            <Button
              className={classes.lngButton}
              variant="outlined"
              size="small"
              onClick={() => setLanguage('pl')}>
              PL
            </Button>
          </div>
        </Toolbar>
      </AppBar>

      <LeftDrawer></LeftDrawer>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <BusContainer>
          <BasicLayout></BasicLayout>
        </BusContainer>
      </main>

      <RightDrawer></RightDrawer>

    </div>
  );
}
