import React from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const AllChildrenFlex = (props) => {
  const classes = makeStyles((theme: Theme) => createStyles({
    root: {
      display: 'flex',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }))();
  return <div className={classes.root}>{props.children}</div>;
}

export const selectFullWidthStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      width: '100%',
    }
  }),
);

export const drawerWidth = 320;

export const drawerStyle = {
  width: drawerWidth,
  flexShrink: 0,
}

export const drawerPaperStyle = {
  width: drawerWidth,
}

export const toolbarStyle = (theme: Theme) => ({
  toolbar: theme.mixins.toolbar
})

export const nestedStyle = (theme: Theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  }
})

export const defaultDrawerStyle = (theme: Theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  ...nestedStyle(theme),
  toolbar: theme.mixins.toolbar,
  drawer: drawerStyle,
  drawerPaper: { width: drawerWidth },
})
