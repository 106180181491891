import React from 'react';
import styled from 'styled-components'
import { useContext } from 'react';
import { LoadingSpaceContext } from '../context/loadingSpaceContext';
import { AxleLoadContext } from '../context/axle-load.context';
import { grayish, grayishh } from '../shared/styles/colors';

const Bus = styled.div`
    position: relative;
    width: ${props => props.width}px;
    height: ${props => props.length}px;
    border: 2px solid black;
    margin: auto;
    background-color: white;
    box-sizing: content-box;
`

const axleToBusWidthAddition = 100

const Cabine = styled.div`
    margin: auto;
    width: ${props => props.width}px;
    border-bottom: ${props => props.height}px solid ${grayish};
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
`

const FrontAxle = styled.div`
    position: absolute;
    background-color: ${grayishh};
    height: 10px;
    width: ${props => props.width}px;
    top: ${props => props.topDistance}px;
    left: -${axleToBusWidthAddition / 2}px;
    z-index:-1;
`;

const RearAxle = styled.div`
    position: absolute;
    background-color: ${grayishh};
    height: 10px;
    width: ${props => props.width}px;
    top: ${props => props.topDistance}px;
    left: -${axleToBusWidthAddition / 2}px;
    z-index:-1;
`
const getTopPadding = (frontAxleDistance: number, busLength: number) => {
    if (frontAxleDistance <= 0) {
        return Math.abs(frontAxleDistance)
    } else {
        return 0
    }
}

export const BusContainer = (props) => {
    const loadingSpaceContext = useContext(LoadingSpaceContext)
    const axleLoadContext = useContext(AxleLoadContext)

    const axleWidth = loadingSpaceContext.width + axleToBusWidthAddition

    return (
        <div>
            <Cabine
                height={getTopPadding(axleLoadContext.frontAxleDistance, loadingSpaceContext.length) + 70}
                width={loadingSpaceContext.width}
                ></Cabine>
            <Bus
                width={loadingSpaceContext.width}
                length={loadingSpaceContext.length}
            >
                <FrontAxle width={axleWidth} topDistance={axleLoadContext.frontAxleDistance}></FrontAxle>
                <RearAxle width={axleWidth} topDistance={axleLoadContext.rearAxleDistance}></RearAxle>
                {props.children}
            </Bus>
        </div>

    )
}