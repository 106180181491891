import React, { useContext } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { nestedStyle } from '../../../shared/styles/containers';
import Badge from '@material-ui/core/Badge';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import RemoveIcon from '@material-ui/icons/Remove';
import { PaletteContext } from '../../../context/paletteContext';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...nestedStyle(theme),
    }),
)

export interface LoadItemProps {
    id: string
    count: number
    text: string
}

export default function LoadItem(props: LoadItemProps) {
    const classes = useStyles()

    const paletteContext = useContext(PaletteContext)

    return (
        <ListItem className={classes.nested}>
            <Badge badgeContent={props.count} color="primary" anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}>
                <DashboardIcon />
            </Badge>
            <ListItemText primary={props.text} />
            <ButtonGroup color="primary" size="small">
                <Button onClick={() => paletteContext.removePalette(props.id)}><RemoveIcon /></Button>
                <Button onClick={() => paletteContext.increasePaletteCount(props.id)}><AddIcon /></Button>
            </ButtonGroup>
        </ListItem>
    )
}
