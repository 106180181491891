import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import pl from './locales/pl/translation.json'
import en from './locales/en/translation.json'

const resources = {
  en: {
    translation: en,
  },
  pl: {
    translation: pl
  }
}

const language: string = localStorage.getItem('language') || ''

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: language,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;