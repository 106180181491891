import React, { useState } from 'react'
import { defaultTruckLoadingSpace } from '../constants/init-defaults'

export interface LoadingSpaceContext {
    length: number
    width: number
    capacity: number
    setLoadingSpace: (loadingSpace: { length: number, width: number, capacity: number }) => void
}

export const LoadingSpaceContext = React.createContext<LoadingSpaceContext>({} as LoadingSpaceContext)

const LoadingSpaceContextComponent = (props) => {
    const [loadingSpace, setLoadingSpace] = useState({
        length: defaultTruckLoadingSpace.length,
        width: defaultTruckLoadingSpace.width,
        capacity: defaultTruckLoadingSpace.capacity,
    })
    return (
        <LoadingSpaceContext.Provider value={{
            ...loadingSpace,
            setLoadingSpace,
        }}>
            {props.children}
        </LoadingSpaceContext.Provider>
    )
}

export default LoadingSpaceContextComponent
