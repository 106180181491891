import React from "react";
import _ from "lodash";
import { LoadingSpaceContext } from '../context/loadingSpaceContext';
import { PaletteContext } from '../context/paletteContext';
import { Palette } from '../types/palette';
import LoopIcon from '@material-ui/icons/Loop';
import { VerticalCompactContext } from '../context/verticalCompactContext';
import { PreventCollisionContext } from '../context/preventCollisionContext';
import CloseIcon from '@material-ui/icons/Close';
import RGL, { WidthProvider, Layout } from "react-grid-layout";
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';

export let ReactGridLayout = WidthProvider(RGL);

export const BasicLayout = () => {
  return (
    <PreventCollisionContext.Consumer>
      {preventCollisionContext => (
        <VerticalCompactContext.Consumer>
          {verticalCompactContext => (
            <LoadingSpaceContext.Consumer>
              {loadingSpaceContext => (
                <PaletteContext.Consumer>
                  {paletteContext => (
                    <BasicLayoutWithoutContexts
                      paletteContext={paletteContext}
                      loadingSpaceContext={loadingSpaceContext}
                      verticalCompactContext={verticalCompactContext}
                      preventCollisionContext={preventCollisionContext}
                    >
                    </BasicLayoutWithoutContexts>
                  )}
                </PaletteContext.Consumer>
              )}
            </LoadingSpaceContext.Consumer>
          )}
        </VerticalCompactContext.Consumer>

      )}
    </PreventCollisionContext.Consumer>
  )
}

interface BasicLayoutProps {
  paletteContext: PaletteContext
  loadingSpaceContext: LoadingSpaceContext
  verticalCompactContext: VerticalCompactContext
  preventCollisionContext: PreventCollisionContext
}

class BasicLayoutWithoutContexts extends React.PureComponent<BasicLayoutProps, any> {
  static defaultProps = {
    rowHeight: 1,
    margin: [0, 0],
    isResizable: false,
  };

  constructor(props) {
    super(props);
    this.state = {}
  }

  onRemoveItem(i) {
    this.props.paletteContext.removePalette(i)
  }

  onRotatePalette(i) {
    this.props.paletteContext.rotatePalette(i)
  }

  onLayoutChange(layout: Layout[]) {
    this.props.paletteContext.updatePalettesPosition(layout)
  }

  createElement(el: Palette) {
    const btnStyle = {
      position: 'absolute' as 'absolute',
      display: 'flex',
      cursor: "pointer",
      border: '1px solid black',
    }
    const removeStyle = {
      ...btnStyle,
      right: 0,
      top: 0,
    };
    const rotateStyle = {
      ...btnStyle,
      left: 0,
      top: 0,
    };
    const paletteKey = el.i;
    return (
      <div key={paletteKey} data-grid={el}>
        <span className="text">{`${el.w + ' x ' + el.h}`} <br />{el.weight} kg</span>
        <span
          style={rotateStyle}
          onClick={this.onRotatePalette.bind(this, paletteKey)}
        >
          <LoopIcon></LoopIcon>
        </span>
        <span
          style={removeStyle}
          onClick={this.onRemoveItem.bind(this, paletteKey)}
        >
          <CloseIcon></CloseIcon>
        </span>
      </div>
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (state.prevWidth && (props.loadingSpaceContext.width !== state.prevWidth)) {
      ReactGridLayout = WidthProvider(RGL);
    }
    return null;
  }

  componentDidUpdate() {
    this.setState({ prevWidth: this.props.loadingSpaceContext.width });
  }
  render() {
    return (
      <ResponsiveGridLayout
        verticalCompact={this.props.verticalCompactContext.verticalCompact}
        preventCollision={this.props.preventCollisionContext.preventCollision}
        width={this.props.loadingSpaceContext.width}
        cols={{ xxs: this.props.loadingSpaceContext.width }}
        onLayoutChange={this.onLayoutChange.bind(this)}
        {...this.props}
      >
        {_.map(this.props.paletteContext.palettes, el => this.createElement(el))}
      </ResponsiveGridLayout>
    );
  }
}

