import { Palette } from '../../types/palette'
import { AxlePressure } from '../axle-load.context'

export const calculatePressure = (
    palettes: Palette[],
    frontAxleDistance: number,
    rearAxleDistance: number,
): AxlePressure => {
    return palettes.reduce<AxlePressure>((overallWeight: AxlePressure, currPalette: Palette): AxlePressure => {
        const palettePosition = currPalette.h / 2 + currPalette.y

        if (palettePosition <= frontAxleDistance) {
            return { ...overallWeight, frontLoadAxlePressure: overallWeight.frontLoadAxlePressure + currPalette.weight }
        } else if (palettePosition >= rearAxleDistance) {
            return { ...overallWeight, rearLoadAxlePressure: overallWeight.rearLoadAxlePressure + currPalette.weight }
        }

        const distanceBetweenAxles = rearAxleDistance - frontAxleDistance
        const paletteDistanceToFrontAxle = palettePosition - frontAxleDistance

        const paletteWeightForFrontDistance = currPalette.weight * (1 - paletteDistanceToFrontAxle / distanceBetweenAxles)
        const paletteWeightForRearDistance = currPalette.weight - paletteWeightForFrontDistance

        // const paletteDistanceToRearAxle = rearAxleDistance - palettePosition
        // console.log('spalettePosition', palettePosition)
        // console.log('distanceBetweenAxles', distanceBetweenAxles);
        // console.log('distance to FrontAxle', paletteDistanceToFrontAxle);
        // console.log('distance to RearAxle', paletteDistanceToRearAxle);
        // console.log('palette % position distribution', 1 - (paletteDistanceToFrontAxle / distanceBetweenAxles));
        // console.log('paletteWeightForFrontDistance', paletteWeightForFrontDistance);
        // console.log('paletteWeightForRearDistance', paletteWeightForRearDistance);
        // console.log('frontLoadAxlePressure', overallWeight.frontLoadAxlePressure + paletteWeightForFrontDistance);
        // console.log('rearLoadAxlePressure', overallWeight.rearLoadAxlePressure + paletteWeightForRearDistance);

        return {
            frontLoadAxlePressure: overallWeight.frontLoadAxlePressure + paletteWeightForFrontDistance,
            rearLoadAxlePressure: overallWeight.rearLoadAxlePressure + paletteWeightForRearDistance
        }
    }, { frontLoadAxlePressure: 0, rearLoadAxlePressure: 0 })
}
