import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { defaultTruckLoadingSpace, truckSpaces, LoadingSpace, containerSpaces, palettesSpaces } from '../../constants/init-defaults';
import { DialogContentText, useTheme, InputLabel, MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { LoadingSpaceContext } from '../../context/loadingSpaceContext';
import { AllChildrenFlex, selectFullWidthStyles } from '../../shared/styles/containers';
import { getNumberInputError } from '../../shared/common';
import { useTranslation } from 'react-i18next';
import { setLoadingSpace, enterAnyDimensions } from '../../locales/translation-keys';

export interface LoadingSpaceDialogProps {
  open: boolean;
  handleClose: () => void;
}

export default function LoadingSpaceDialog(props: LoadingSpaceDialogProps) {
  const { t } = useTranslation()
  const theme = useTheme()
  const classes = selectFullWidthStyles()
  const selectStyles = { width: '100%', margin: '10px' }

  const loadingSpaceContext = useContext(LoadingSpaceContext)

  const [selectedTruck, setSelectedTruck] = useState(0)
  const [selectedContainer, setSelectedContainer] = useState(0)
  const [selectedDifferent, setSelectedDifferent] = useState(0)

  const [width, setWidth] = useState<any>(defaultTruckLoadingSpace.width)
  const [length, setLength] = useState<any>(defaultTruckLoadingSpace.length)
  const [capacity, setCapacity] = useState<any>(defaultTruckLoadingSpace.capacity)

  const handleTruckDropdownChange = (event: any) => {
    const selectedValue: LoadingSpace = truckSpaces[+event.target.value];
    setSelectedTruck(+event.target.value)
    setWidth(selectedValue.width)
    setLength(selectedValue.length)
    setCapacity(selectedValue.capacity)
  }

  const handleContainerDropdownChange = (event: any) => {
    const selectedValue: LoadingSpace = containerSpaces[+event.target.value]
    setSelectedContainer(+event.target.value)
    setWidth(selectedValue.width)
    setLength(selectedValue.length)
    setCapacity(selectedValue.capacity)
  }

  const handleDifferentDropdownChange = (event: any) => {
    const selectedValue: LoadingSpace = palettesSpaces[+event.target.value]
    setSelectedDifferent(+event.target.value)
    setWidth(selectedValue.width)
    setLength(selectedValue.length)
    setCapacity(selectedValue.capacity)
  }

  const handleWidthChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string = event.target.value.replace(',', '.')
    setWidth(value)
  }

  const handleLenghtChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string = event.target.value.replace(',', '.')
    setLength(value)
  }

  const handleCapacityChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value: string = event.target.value.replace(',', '.')
    setCapacity(value)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    loadingSpaceContext.setLoadingSpace({ width: +width, length: +length, capacity: +capacity })
    props.handleClose()
  }

  const lengthError: string = getNumberInputError(length + '')
  const widthError: string = getNumberInputError(width + '')
  const capacityError: string = getNumberInputError(capacity + '')
  const isFormInvalid: boolean = !!widthError || !!lengthError || !!capacityError

  return (
    <Dialog maxWidth={"md"} open={props.open} onClose={props.handleClose}>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>

        <DialogTitle>{t(setLoadingSpace)}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t(enterAnyDimensions)}
          </DialogContentText>
          <AllChildrenFlex>
            <TextField
              label={`${t('width')} (cm)`}
              variant="outlined"
              className='text-field'
              error={!!widthError}
              helperText={widthError}
              value={width}
              onChange={handleWidthChanged}
            />
            <TextField
              label={`${t('length')} (cm)`}
              variant="outlined"
              className='text-field'
              error={!!lengthError}
              helperText={lengthError}
              value={length}
              onChange={handleLenghtChanged}
            />
            <TextField
              label={`${t('capacity')} (kg)`}
              variant="outlined"
              className='text-field'
              error={!!capacityError}
              helperText={capacityError}
              value={capacity}
              onChange={handleCapacityChanged}
            />
          </AllChildrenFlex>

          <DialogContentText style={{ marginTop: theme.spacing(1) }}>
            {t('orChooseFromPredefined')}
          </DialogContentText>

          <div style={selectStyles}>
            <FormControl variant="outlined">
              <InputLabel id="trucks-label">{t('vehicles')}</InputLabel>
              <Select
                labelId="trucks-label"
                className={classes.select}
                value={selectedTruck}
                onChange={handleTruckDropdownChange}
                labelWidth={60}
              >
                {truckSpaces.map((v, index) => (
                  <MenuItem key={index} value={index}>{v.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div style={selectStyles}>
            <FormControl variant="outlined">
              <InputLabel id="containers-label">{t('containers')}</InputLabel>
              <Select
                labelId="containers-label"
                className={classes.select}
                value={selectedContainer}
                onChange={handleContainerDropdownChange}
                labelWidth={80}
              >
                {containerSpaces.map((v, index) => (
                  <MenuItem key={index} value={index}>{v.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div style={selectStyles}>
            <FormControl variant="outlined">
                <InputLabel id="others-label">{t('others')}</InputLabel>
              <Select
                labelId="others-label"
                className={classes.select}
                value={selectedDifferent}
                onChange={handleDifferentDropdownChange}
                labelWidth={30}
              >
                {palettesSpaces.map((v, index) => (
                  <MenuItem key={index} value={index}>{v.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose} color="primary">
            {t('cancel')}
        </Button>
          <Button onClick={handleSubmit} color="primary" disabled={isFormInvalid} type="submit">
            {t('set')}
        </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
