import i18n from "i18next";
export interface LoadingSpace {
    name: string
    length: number
    width: number
    height: number
    capacity: number
}

export const truckSpaces: LoadingSpace[] = [
    { name: `${i18n.t(`bus`)} 8 EP 420x220 cm | 1500 kg`, length: 420, width: 220, height: 220, capacity: 1500 },
    { name: `${i18n.t(`bus`)} 10 EP 490x220 cm | 1500 kg`, length: 490, width: 220, height: 230, capacity: 1500 },
    { name: `${i18n.t(`bus`)} 12 EP 590x230 cm | 3500 kg`, length: 590, width: 230, height: 230, capacity: 3500 },
    { name: `${i18n.t(`truck`)} 10 EP 400x241 cm | 1505 kg`, length: 400, width: 241, height: 280, capacity: 1505 },
    { name: `${i18n.t(`truck`)} 15 EP 620x245 cm | 5000 kg`, length: 620, width: 245, height: 240, capacity: 5000 },
    { name: `${i18n.t(`truck`)} 18 EP 720x245 cm | 6000 kg`, length: 720, width: 245, height: 240, capacity: 6000 },
    { name: `${i18n.t(`truck`)} 20 EP 820x245 cm | 8000 kg`, length: 820, width: 245, height: 240, capacity: 8000 },
    { name: `${i18n.t(`semitrailerStandard`)} 33 EP 1360x245 cm | 24000 kg`, length: 1360, width: 245, height: 270, capacity: 24000 },
    { name: `${i18n.t(`trailerSet`)} 765x240 cm | 6000 kg`, length: 765, width: 240, height: 270, capacity: 6000 },
    { name: `${i18n.t(`megaTrailler`)} 33 EP 1360x250 cm | 24000 kg`, length: 1360, width: 250, height: 300, capacity: 24000 },
    { name: `${i18n.t(`trailerSet`)} 775x240 cm | 6000 kg`, length: 775, width: 240, height: 305, capacity: 6000 },
]

export const containerSpaces: LoadingSpace[] = [
    { name: `${i18n.t(`refrigeratedContainer`)} 20\` 545x227 cm | 20800 kg`, length: 545, width: 227, height: 226, capacity: 20800 },
    { name: `${i18n.t(`seaContainer`)} 20\` 589x237 cm | 21670 kg`, length: 589, width: 237, height: 238, capacity: 21670 },
    { name: `${i18n.t(`refrigeratedContainer`)} 40\` 1149x227 cm | 25580 kg`, length: 1149, width: 227, height: 219, capacity: 25580 },
]

export const palettesSpaces: LoadingSpace[] = [
    { name: `1/2 ${i18n.t('euroPallet')} 60x80 cm | 25 kg`, length: 80, width: 60, height: 100, capacity: 25 },
    { name: `${i18n.t('euroPallet')} 120x80 cm | 25 kg`, length: 80, width: 120, height: 100, capacity: 25 },
    { name: `${i18n.t('industrialPalette')} (ISO) 120x100 cm | 25 kg`, length: 100, width: 120, height: 100, capacity: 25 },
    { name: `${i18n.t('IBCcontainer')} 120x100 cm | 25 kg`, length: 100, width: 120, height: 100, capacity: 25 },
    { name: `${i18n.t('enlargedEuroPalette')} 120x120 cm | 25 kg`, length: 120, width: 120, height: 100, capacity: 25 },
]

export const defaultTruckLoadingSpaceIndex: number = 0
export const defaultTruckLoadingSpace: LoadingSpace = truckSpaces[defaultTruckLoadingSpaceIndex]
export const defaultPaletteLoadingSpaceIndex: number = 1
export const defaultPaletteLoadingSpace: LoadingSpace = palettesSpaces[defaultPaletteLoadingSpaceIndex]

export const defaulFrontAxletDistance: number = -150
export const defaulRearAxleDistance: number = 300

export const defaultFrontNoPressureLoad: number = 1200
export const defaultRearNoPressureLoad: number = 800

export const defaultFrontMaxPreasureLoad: number = 1750
export const defaultRearMaxPreasureLoad: number = 2250